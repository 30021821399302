const PREFIXO = '@med'

// SessionStorage
export const SS_SCROLL_CATALOGO = `${PREFIXO}:posicao-catalogo`
export const SS_SCROLL_PESQUISA = `${PREFIXO}:posicao-pesquisa`

// LocalStorage
export const LS_HISTORICO_PESQUISA = `${PREFIXO}:historico-pesquisa`

// Cookies
export const COOKIE_TOKEN_JWT = 'med-jwt'
export const COOKIE_URL_LOJA_MED = 'med-url-loja'
export const COOKIE_LOJA_MED = 'med-configs-loja'
