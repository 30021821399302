var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"12ee896cf80879305a3b18d082cb7b31f7aeb1b0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

// @issue https://github.com/mobilestock/backend/issues/663
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: Number(process.env.TRACES_SAMPLE_RATE),
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,

  replaysOnErrorSampleRate: Number(process.env.REPLAYS_ON_ERROR_SAMPLE_RATE),
  replaysSessionSampleRate: Number(process.env.REPLAYS_SESSION_SAMPLE_RATE),
  sendDefaultPii: true,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
      networkDetailAllowUrls: [/(http|https)/],
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false
    })
  ]
})
