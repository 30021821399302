import Cookies from 'js-cookie'
import Head from 'next/head'
import { ReactNode, createContext, useContext } from 'react'
import useSWR from 'swr'

import { COOKIE_LOJA_MED } from '@/databases/local'

type typeBaseProdutos = 'ML' | 'MS'
export interface ConfiguracoesLojaProps {
  base_produtos: typeBaseProdutos
  id_revendedor: number
  nome: string
  precos: PropsRemarcacao[]
  telefone: string
  tipo_remarcacao: 'PERCENTUAL' | 'VALOR'
  url: string
  lista: PropsRemarcacao[]
}
interface PropsConfiguracoesLojaContext {
  configsLoja: ConfiguracoesLojaProps | null
}

export interface PropsRemarcacao {
  ate: string
  remarcacao: string
  id_remarcacao?: number
}

const ConfiguracoesLojaContext = createContext({} as PropsConfiguracoesLojaContext)

export const ConfiguracoesLojaProvider: React.FC<{ children: ReactNode }> = props => {
  const { data } = useSWR(
    COOKIE_LOJA_MED,
    url => {
      const cookie = Cookies.get(url)
      return JSON.parse(cookie || 'null')
    },
    {
      refreshInterval: 5,
      revalidateOnMount: true
    }
  )
  const configsLoja: Readonly<PropsConfiguracoesLojaContext['configsLoja']> = data

  return (
    <>
      <Head>
        <title>{configsLoja?.nome || ''}</title>
      </Head>
      <ConfiguracoesLojaContext.Provider value={{ configsLoja }}>{props.children}</ConfiguracoesLojaContext.Provider>
    </>
  )
}

export function useConfiguracoesLoja(): PropsConfiguracoesLojaContext {
  const context = useContext(ConfiguracoesLojaContext)

  return context
}
