import jwt from 'jsonwebtoken'

import { typePermissao } from '@/hooks/useAuth'

export interface PropsJWTMed {
  id_revendedor?: number
  nome?: string
  permissao: typePermissao
  telefone?: string
  url?: string
}

export default {
  descompactaJWT(JWT: string): PropsJWTMed {
    const dadosDescompactados = jwt.decode(JWT) as PropsJWTMed

    return dadosDescompactados
  }
}
