/** @issue https://github.com/mobilestock/frontend/issues/18 */
import axios from 'axios'
import { useRouter } from 'next/router'
import React, { ReactNode, RefObject, createContext, useContext, useEffect, useRef, useState } from 'react'
import { useSWRInfinite } from 'swr'

import { ProdutoCatalogo } from '../components/containers/CardItemCatalogo'
import { api } from '../services/api'
import { useGlobal } from './useGlobal'

export interface PropsFiltro {
  id: number | 'MELHOR_FABRICANTE' | 'LANCAMENTO' | 'MENOR_PRECO' | 'PROMOCAO'
  nome: string
}

interface CatalogoContextProps {
  data: ProdutoCatalogo[][] | undefined
  loaderRef: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
  filtroSelecionado: PropsFiltro['id'] | undefined
  alterarFiltro: (filtro: PropsFiltro['id']) => void
  carregando: boolean
  useEffectCompartilhado: () => void
}

const CatalogoContext = createContext<CatalogoContextProps>({} as CatalogoContextProps)

export const CatalogoProvider: React.FC<{ children: ReactNode }> = props => {
  const [filtroSelecionado, setFiltroSelecionado] = useState<PropsFiltro['id'] | undefined>()
  const observer = useRef<IntersectionObserver>()
  const loaderRef = useRef<HTMLDivElement | null>(null)
  const router = useRouter()
  const { exibirErroCatch } = useGlobal()

  const { data, setSize, isValidating } = useSWRInfinite<ProdutoCatalogo[], unknown>(
    (page: number, previousPageData) => {
      if (previousPageData && previousPageData == null) return null
      const parametros = new URLSearchParams(window.location.search)
      parametros.set('filtro', (filtroSelecionado || '').toString())
      parametros.set('pagina', (page + 1).toString())
      return `/api_meulook/produtos/catalogo?${parametros}`
    },
    async (uri: string) => {
      try {
        const response = await api.get(uri)
        return response?.data
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          exibirErroCatch(`${error.response?.data.message}, carregando catálogo padrão`)
          setFiltroSelecionado(undefined)
          return []
        }
        exibirErroCatch(error)
      }
    },
    {
      revalidateAll: false,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnReconnect: false,
      errorRetryCount: 3,
      onSuccess: resultados => {
        if (filtroSelecionado === 'PROMOCAO' && resultados.length === 1) {
          setSize(old => old + 1)
        }
      }
    }
  )

  useEffect(() => {
    if (!data?.length || isValidating) return
    setTimeout(() => observarElemento(), 2000)
  }, [isValidating, data])

  const useEffectCompartilhado = () => {
    const parametros = new URLSearchParams(window.location.search)
    const filtro = parametros.get('filtro')
    if (filtro && !data?.length) {
      setFiltroSelecionado(filtro as PropsFiltro['id'])
      parametros.delete('filtro')
      router.push(`?${parametros}`)
    } else {
      observarElemento()
    }
    router.events.on('routeChangeStart', (novaRota: string) => {
      if (window.location.pathname !== novaRota && novaRota === '/') {
        setFiltroSelecionado(undefined)
      }
    })
    return () => pararObservarElemento()
  }

  const observarElemento = () => {
    observer.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        if (entries.some(entry => entry.isIntersecting)) {
          pararObservarElemento()
          if (data?.[data.length - 1]?.length === 0) return null
          else if (data === undefined) return setSize(1)
          setSize(old => old + 1)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1
      }
    )
    if (loaderRef.current) observer.current.observe(loaderRef.current)
  }

  const pararObservarElemento = () => {
    observer.current?.disconnect()
    observer.current = undefined
  }

  const alterarFiltro = (filtro: PropsFiltro['id']) => {
    pararObservarElemento()
    if (filtroSelecionado === filtro) {
      setFiltroSelecionado(undefined)
    } else {
      setFiltroSelecionado(filtro)
    }
  }

  return (
    <CatalogoContext.Provider
      value={{
        data,
        loaderRef,
        filtroSelecionado,
        alterarFiltro,
        carregando: isValidating,
        useEffectCompartilhado
      }}
    >
      {props.children}
    </CatalogoContext.Provider>
  )
}

export function useCatalogo(): CatalogoContextProps {
  return useContext(CatalogoContext)
}
