import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root{
    --cor-primaria: #000000;
    --cor-secundaria: #B50D49;
    --cor-destaque: #0075FF;

    --alerta20: #B50D4833;
    --hover: rgba(0, 0, 0, 0.20);
    --cor-sombra: rgba(0, 0, 0, 0.25);

    --amarelo47: #E6D00B;
    --azul44: #0068DF;
    --branco: #FFFFFF;
    --ciano63: #42D4FF;
    --cinza95: #F1F1F1;
    --cinza87: #DDDDDD;
    --cinza82: #D2D2D2;
    --cinza13: #212121;
    --preto: #000000;
    --verde49: #25D366;
    --verde47: #39B54A;
    --verde45: #43A047;
    --verde60: #43A047;
    --verde: #00FF00;
    --verde36: #00B600;
    --verde34: #228B22;
    --vermelho51: #CC3737;
    --vermelho48: #F50B00;
    --vermelho80: #cc3737;
    --vermelho: #FF0000;

    --card-catalogo-fundo-vermelho: red;
    --card-catalogo-fundo-verde: #00b600;
    --card-catalogo-fundo-azul: #0075FF;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  body {
    background: var(--cinza95);
    color: var(---preto);
    min-height: 100vh;
  }

  input,
  textarea,
  button {
    font: 400 1rem 'Open Sans', sans-serif;
  }

  button {
    cursor: pointer;
    border-radius: 0.1rem;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  @font-face {
    font-family: 'Josefin Sans Std';
    src: url('../../public/fonts/JosefinSansStd-Light.otf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
`
