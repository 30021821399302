import axios from 'axios'
import Cookies from 'js-cookie'

import { COOKIE_LOJA_MED, COOKIE_TOKEN_JWT, COOKIE_URL_LOJA_MED } from '@/databases/local'

import app from '../../package.json'

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_URL_API_MED,
  headers: {
    referer: app.name
  }
})

api.interceptors.request.use(config => {
  const token = Cookies.get(COOKIE_TOKEN_JWT)
  const urlLoja = Cookies.get(COOKIE_URL_LOJA_MED)

  if (urlLoja) config.headers.Loja = urlLoja
  if (token) config.headers.Authorization = `Bearer ${token}`

  return config
})

api.interceptors.response.use(
  resposta => {
    const configuracoesLoja = Cookies.get(COOKIE_LOJA_MED)
    const novasConfigsLoja = resposta.headers['med-loja']
    if (configuracoesLoja !== novasConfigsLoja) {
      Cookies.set(COOKIE_LOJA_MED, novasConfigsLoja)
    }

    return resposta
  },
  error => {
    if (Cookies.get(COOKIE_TOKEN_JWT) && Number(error?.response?.status) === 401) {
      delete api.defaults.headers.Authorization
      Cookies.remove(COOKIE_TOKEN_JWT)
      window.location.href = '/'
    }

    return Promise.reject(error)
  }
)
