import Cookies from 'js-cookie'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { COOKIE_TOKEN_JWT, COOKIE_URL_LOJA_MED } from '@/databases/local'
import { api } from '@/services/api'
import nodeTools, { PropsJWTMed } from '@/utils/nodeTools'

export interface PropsUser extends PropsJWTMed {
  authorization?: string
  url?: string
}

export type typePermissao = 'LOJA' | 'CLIENTE'

interface PropsAuthContext {
  signIn: (tokenJWT: string) => void
  signOut: () => void
  user: PropsUser | null
}

const AuthContext = createContext({} as PropsAuthContext)

export const AuthProvider: React.FC<{ children: ReactNode }> = props => {
  const [user, setUser] = useState<PropsAuthContext['user']>(null)

  useEffect(() => {
    const novoToken = Cookies.get(COOKIE_TOKEN_JWT)
    if (user?.authorization !== novoToken) {
      if (novoToken) {
        signIn(novoToken)
      } else {
        signOut()
      }
    }
  }, [])

  function signIn(tokenJWT: string): void {
    const dadosJWT = nodeTools.descompactaJWT(tokenJWT)
    let dadosSalvar = {
      authorization: tokenJWT,
      id_revendedor: dadosJWT.id_revendedor || 0,
      url: Cookies.get(COOKIE_URL_LOJA_MED),
      nome: dadosJWT.nome || '',
      permissao: user?.permissao || dadosJWT.permissao,
      telefone: dadosJWT.telefone || ''
    }

    Cookies.set(COOKIE_TOKEN_JWT, dadosSalvar.authorization)
    if (dadosJWT.permissao === 'LOJA') {
      dadosSalvar.url = process.env.NEXT_PUBLIC_APP_MODE === 'production' ? window.location.host : dadosJWT.url
    }
    if (!api.defaults.headers.Authorization) api.defaults.headers.Authorization = `Bearer ${tokenJWT}`
    if (!api.defaults.headers.Loja && dadosSalvar?.url) api.defaults.headers.Loja = dadosSalvar?.url
    setUser(dadosSalvar)
  }

  function signOut(): void {
    Cookies.remove(COOKIE_TOKEN_JWT)
    delete api.defaults.headers.Authorization
    setUser(null)
  }

  return <AuthContext.Provider value={{ signIn, signOut, user }}>{props.children}</AuthContext.Provider>
}

export function useAuth(): PropsAuthContext {
  const context = useContext(AuthContext)

  return context
}
