import { FormHandles } from '@unform/core'
import axios from 'axios'
import copy from 'copy-to-clipboard'
import Cookies from 'js-cookie'
import { useSnackbar } from 'notistack'
import React, { ReactNode, RefObject, createContext, useContext, useRef } from 'react'
import { ValidationError } from 'yup'

import { COOKIE_URL_LOJA_MED } from '@/databases/local'

interface PropsGlobalContext {
  compartilharLink: (tela: string, titulo?: string) => void
  debounce(funcao: Callback, milissegundos?: number): Promise<void>
  exibirErroCatch(error: unknown, formRef?: RefObject<FormHandles>): void
}

type Callback = () => void

const GlobalContext = createContext({} as PropsGlobalContext)

export const GlobalProvider: React.FC<{ children: ReactNode }> = props => {
  const processamentoPendente = useRef<NodeJS.Timeout | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  function compartilharLink(tela: string, titulo = ''): void {
    let url = window.location.origin
    if (process.env.NEXT_PUBLIC_APP_MODE !== 'production') {
      const urlLoja = Cookies.get(COOKIE_URL_LOJA_MED)
      if (!urlLoja) {
        enqueueSnackbar('Erro ao tentar gerar link de cliente', { variant: 'error' })
        return
      }

      url = `${url}/med/${urlLoja}`
    }

    copiar(titulo, `${url}/${tela}`)
  }

  function copiar(titulo: string, textoCopiar: string): void {
    if (navigator?.canShare?.()) {
      navigator.share({ title: titulo, url: textoCopiar })
    } else {
      copy(textoCopiar)
      enqueueSnackbar('Link copiado para a area de transferência!', { variant: 'default' })
    }
  }

  async function debounce(funcao: Callback, milissegundos = 500) {
    if (processamentoPendente?.current) {
      clearTimeout(processamentoPendente.current)
    }

    processamentoPendente.current = setTimeout(() => {
      funcao()
      processamentoPendente.current = null
    }, milissegundos)
  }

  function exibirErroCatch(error: unknown, formRef?: RefObject<FormHandles>) {
    let message = 'Erro ao realizar operação'
    if (axios.isAxiosError(error)) {
      message = error.response?.data.mensagem || error.response?.data.message
    } else if (error instanceof ValidationError) {
      const erros: PropsErroYup = {}
      error.inner.forEach(erro => {
        if (erro.path) erros[erro.path] = erro.message
      })
      if (formRef) return formRef.current?.setErrors(erros)
      message = 'Referência de formulário não encontrada para exibir erros'
    } else if (error instanceof Error) {
      message = error.message
    } else if (typeof error === 'string') {
      message = error
    }
    enqueueSnackbar(message, { variant: 'error' })
  }

  return (
    <GlobalContext.Provider value={{ compartilharLink, debounce, exibirErroCatch }}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export function useGlobal(): PropsGlobalContext {
  const context = useContext(GlobalContext)
  return context
}
