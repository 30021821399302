import type { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from 'styled-components'

import { AuthProvider } from '@/hooks/useAuth'
import { CatalogoProvider } from '@/hooks/useCatalogo'
import { ConfiguracoesLojaProvider } from '@/hooks/useConfiguracoesLoja'
import { GlobalProvider } from '@/hooks/useGlobal'
import { PesquisaProvider } from '@/hooks/usePesquisa'
import theme from '@/styles/theme'

import { GlobalStyle } from '../styles/globals'

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta name="language" content="pt-br" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#000" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#000" />
        <meta name="msapplication-navbutton-color" content="#000" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta property="og:image" content="/icon-192.svg" />
        <meta property="og:image:type" content="image/svg+xml" />
        <meta property="og:image:width" content="192" />
        <meta property="og:image:height" content="192" />
      </Head>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <GlobalProvider>
            <AuthProvider>
              <ConfiguracoesLojaProvider>
                <PesquisaProvider>
                  <CatalogoProvider>
                    <Component {...pageProps} />
                  </CatalogoProvider>
                </PesquisaProvider>
              </ConfiguracoesLojaProvider>
            </AuthProvider>
          </GlobalProvider>
        </SnackbarProvider>

        <GlobalStyle />
      </ThemeProvider>
    </>
  )
}
export default MyApp
