export default {
  primary: '#000000',
  secondary: '#B50D49',
  tertiary: '#0075FF',
  shadow: '#00000040',

  black: '#000000',
  blue44: '#0068DF',
  blue41: '#303F9F',
  blue50: '#0075FF',
  green34: '#228B22',
  green38: '#0CB536',
  green45: '#43A047',
  gray82: '#D2D2D2',
  gray96: '#F5F5F5',
  orange48: '#F56200',
  red48: '#FF0000',
  red51: '#CC3737',
  red38A20: '#B50D4833',
  white: '#FFFFFF',
  yellow50: '#FFC700'
}
