import { ReactNode, createContext, useContext, useRef, useState } from 'react'

export type ChaveSetter = Uppercase<keyof FiltrosPesquisa>
export type ValorSetter = string | PropsFornecedores
export type OrdemPesquisa =
  | 'MAIS_RELEVANTE'
  | 'MENOR_PRECO'
  | 'MAIOR_PRECO'
  | 'MELHOR_AVALIADO'
  | 'MAIS_ANTIGO'
  | 'MAIS_RECENTE'
export type SituacoesFornecedor = 'RUIM' | 'REGULAR' | 'EXCELENTE' | 'MELHOR_FABRICANTE'
export type TipoEstoque = 'TODOS' | 'FULFILLMENT'
export type TipoLinha = 'Adulto' | 'Infantil'
export type TipoSexo = 'MASCULINO' | 'FEMININO'
interface PesquisaContextProps {
  alternarFiltroSelecionado: (chave: ChaveSetter, valor: ValorSetter) => void
  parametrosDefinidos: PesquisaArmazenada
  listaFiltros: ParametrosPesquisa
  gerarLinkCompartilhar: () => string
  setListaFiltros: (novaLista: ParametrosPesquisa) => void
  setOrdem: (novaOrdem: OrdemPesquisa) => void
  setParametros: (novosParametros: ParametrosPesquisa) => void
  setPesquisa: (novaPesquisa: string) => void
}

export interface PropsFornecedores {
  id: number
  nome: string
  foto?: string
  melhor_fabricante?: boolean
}

export interface FiltrosPesquisa {
  categorias: string[]
  cores: string[]
  estoque: TipoEstoque[]
  linhas: TipoLinha[]
  numeros: string[]
  reputacoes: SituacoesFornecedor[]
  sexos: Capitalize<TipoSexo>[]
  fornecedores: PropsFornecedores[]
}

export interface ParametrosPesquisa extends FiltrosPesquisa {
  ordenar: OrdemPesquisa
  pesquisa: string
}

export interface PesquisaArmazenada extends ParametrosPesquisa {
  parametrosString: string
  quantidadeFiltrosSelecionados: number
}

const PesquisaContext = createContext<PesquisaContextProps>({} as PesquisaContextProps)

export const PesquisaProvider: React.FC<{ children: ReactNode }> = props => {
  const parametrosIniciais = useRef({
    pesquisa: '',
    ordenar: 'MAIS_RELEVANTE',
    categorias: [],
    cores: [],
    linhas: [],
    sexos: [],
    numeros: [],
    parametrosString: '',
    quantidadeFiltrosSelecionados: 0,
    reputacoes: [],
    estoque: ['TODOS'],
    fornecedores: []
  } as PesquisaArmazenada)

  /**
   * @issue: https://github.com/FabioMobileStock/meulook/issues/693
   */
  const [listaFiltros, setListaFiltros] = useState<ParametrosPesquisa>({
    ...parametrosIniciais.current,
    reputacoes: ['MELHOR_FABRICANTE', 'EXCELENTE', 'REGULAR', 'RUIM'],
    estoque: ['TODOS', 'FULFILLMENT']
  })
  const [parametrosDefinidos, setParametrosDefinidos] = useState<PesquisaArmazenada>({ ...parametrosIniciais.current })

  const alternarFiltroSelecionado = (chave: ChaveSetter, valor: ValorSetter) => {
    function adicionaOuRemoveValor<T extends keyof FiltrosPesquisa>(lista: ValorSetter[]): FiltrosPesquisa[T] {
      let retorno = []
      retorno = lista.concat(valor)
      if (lista.includes(valor)) {
        retorno = lista.filter(item => item !== valor)
      }

      return retorno as FiltrosPesquisa[T]
    }
    const parametros = { ...parametrosDefinidos }
    switch (chave) {
      case 'CATEGORIAS':
        parametros.categorias = adicionaOuRemoveValor<'categorias'>(parametros.categorias)
        break
      case 'CORES':
        parametros.cores = adicionaOuRemoveValor<'cores'>(parametros.cores)
        break
      case 'LINHAS':
        parametros.linhas = adicionaOuRemoveValor<'linhas'>(parametros.linhas)
        break
      case 'SEXOS':
        parametros.sexos = adicionaOuRemoveValor<'sexos'>(parametros.sexos)
        break
      case 'NUMEROS':
        parametros.numeros = adicionaOuRemoveValor<'numeros'>(parametros.numeros)
        break
      case 'REPUTACOES':
        parametros.reputacoes = adicionaOuRemoveValor<'reputacoes'>(parametros.reputacoes)
        break
      case 'ESTOQUE':
        parametros.estoque = [valor as TipoEstoque]
        break
      case 'FORNECEDORES': {
        const valorFornecedor = valor as PropsFornecedores
        if (parametros.fornecedores.find(fornecedor => fornecedor.id === valorFornecedor.id)) {
          parametros.fornecedores = parametros.fornecedores.filter(fornecedor => fornecedor.id !== valorFornecedor.id)
        } else parametros.fornecedores = [...parametros.fornecedores, valorFornecedor]
        break
      }
      default:
        break
    }
    salvarParametros({ ...parametros })
  }

  const alterarOrdem = (novaOrdem: OrdemPesquisa) => {
    const definidos = { ...parametrosDefinidos }
    definidos.ordenar = novaOrdem
    salvarParametros(definidos)
  }

  const alterarPesquisa = (novaPesquisa: string) => {
    const novosParametros = { ...parametrosIniciais.current }
    novosParametros.pesquisa = novaPesquisa
    salvarParametros(novosParametros)
  }

  const gerarLinkCompartilhar = (): string => {
    let url = 'pesquisa'
    if (parametrosDefinidos.parametrosString?.length === 0) return url
    const parametros = new URLSearchParams(parametrosDefinidos.parametrosString)
    const parametroFornecedores = parametros.get('fornecedores') || ''
    if (parametroFornecedores.length > 0) {
      const dadosFornecedores = parametrosDefinidos.fornecedores.map(fornecedor => ({
        id: fornecedor.id,
        nome: fornecedor.nome
      }))
      parametros.set('fornecedores', JSON.stringify(dadosFornecedores))
    }
    return `${url}?${parametros}`
  }

  const salvarParametros = (novosParametros: ParametrosPesquisa) => {
    let parametrosString = ''
    let quantidadeFiltrosSelecionados = 0
    const url = new URLSearchParams()
    Object.keys(novosParametros).forEach(chave => {
      if (['parametrosString', 'quantidadeFiltrosSelecionados'].includes(chave)) return
      const valor = novosParametros[chave as keyof ParametrosPesquisa]
      if (!valor?.length) return
      if (chave === 'ordenar' && valor === parametrosIniciais.current.ordenar) return
      if (chave === 'estoque' && valor[0] === parametrosIniciais.current.estoque[0]) return
      if (!['pesquisa', 'ordenar'].includes(chave)) {
        quantidadeFiltrosSelecionados += valor.length
      }
      if (Array.isArray(valor)) {
        if (chave === 'fornecedores') {
          const valorFornecedores = valor as PropsFornecedores[]
          url.append(chave, valorFornecedores.map(fornecedor => fornecedor.id).join(','))
        } else {
          url.append(chave, valor.join(','))
        }
      } else url.append(chave, valor)
    })
    parametrosString = url.toString()
    setParametrosDefinidos(parametrosAntigos => {
      return {
        ...parametrosAntigos,
        ...novosParametros,
        parametrosString,
        quantidadeFiltrosSelecionados
      }
    })
  }

  return (
    <PesquisaContext.Provider
      value={{
        alternarFiltroSelecionado,
        gerarLinkCompartilhar,
        parametrosDefinidos,
        listaFiltros,
        setListaFiltros,
        setOrdem: alterarOrdem,
        setParametros: salvarParametros,
        setPesquisa: alterarPesquisa
      }}
    >
      {props.children}
    </PesquisaContext.Provider>
  )
}

export function usePesquisa(): PesquisaContextProps {
  return useContext(PesquisaContext)
}
