import defaultColors from './defaultColors'

export default {
  colors: {
    text: {
      default: defaultColors.black,
      contrast: defaultColors.white
    },
    container: {
      default: defaultColors.gray82,
      shadow: defaultColors.shadow,
      outline: defaultColors.gray96
    },
    button: {
      default: defaultColors.primary,
      confirm: defaultColors.green45,
      cancel: defaultColors.red48
    },
    input: {
      default: defaultColors.white,
      error: defaultColors.red38A20
    },
    alert: {
      success: defaultColors.green34,
      tip: defaultColors.yellow50,
      warning: defaultColors.orange48,
      urgent: defaultColors.red48
    }
  }
}
